
// import CryptoJS from 'crypto-js';

import { useEffect } from "react";

// const hashPhoneNumber = (phoneNumber: any) => {
//     // Remove any non-numeric characters (optional)
//     const cleanNumber = phoneNumber.replace(/\D/g, '');
//     // Hash the cleaned phone number using SHA-256
//     const hashedNumber = CryptoJS.SHA256(cleanNumber).toString(CryptoJS.enc.Hex);
//     return hashedNumber;
// };

const formatPhoneNumber = (phoneNumber: any) => {
    // Remove all non-digits
    let cleaned = phoneNumber.replace(/\D/g, '');

    // Check if the number starts with a '+' and remove it if present
    if (cleaned.startsWith('+')) {
        cleaned = cleaned.slice(1);
    }

    // Check if the number starts with '00' and replace it with '+'
    if (cleaned.startsWith('00')) {
        cleaned = `+${cleaned.slice(2)}`;
    }

    // Add the country code if missing (assuming a default country code, adjust as needed)
    if (!cleaned.startsWith('+')) {
        cleaned = `+1${cleaned}`; // Defaulting to country code 1 (United States), adjust for your region
    }

    return cleaned;
};
// const hashEmail = (email: any) => {
//     // Normalize the email address (optional)
//     const normalizedEmail = email.trim().toLowerCase();

//     // Hash the normalized email using SHA-256
//     const hashedEmail = CryptoJS.SHA256(normalizedEmail).toString(CryptoJS.enc.Hex);

//     return hashedEmail;
// };

export const useTikTokAnalytics = (sdkId: string) => {
    useEffect(() => {
      if (typeof window !== 'undefined') {
        (function (w: Window, d: Document, t: string) {
          w.TiktokAnalyticsObject = t;
          const ttq = (w[t] = w[t] || []) as any;
  
          ttq.methods = [
            'page', 'track', 'identify', 'instances', 'debug', 'on', 'off',
            'once', 'ready', 'alias', 'group', 'enableCookie', 'disableCookie',
            'holdConsent', 'revokeConsent', 'grantConsent'
          ];
  
          ttq.setAndDefer = function (context: any, method: string) {
            context[method] = function (...args: any[]) {
              context.push([method, ...args]);
            };
          };
  
          for (const method of ttq.methods) {
            ttq.setAndDefer(ttq, method);
          }
  
          ttq.instance = function (id: string) {
            const instance = ttq._i && ttq._i[id] || [];
            for (const method of ttq.methods) {
              ttq.setAndDefer(instance, method);
            }
            return instance;
          };
  
          ttq.load = function (sdkId: string, options?: any) {
            const scriptUrl = 'https://analytics.tiktok.com/i18n/pixel/events.js';
            ttq._i = ttq._i || {};
            ttq._i[sdkId] = [];
            ttq._i[sdkId]._u = scriptUrl;
            ttq._t = ttq._t || {};
            ttq._t[sdkId] = +new Date();
            ttq._o = ttq._o || {};
            ttq._o[sdkId] = options || {};
  
            const script = d.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = `${scriptUrl}?sdkid=${sdkId}&lib=${t}`;
            const firstScript = d.getElementsByTagName('script')[0];
            firstScript.parentNode?.insertBefore(script, firstScript);
          };
  
          ttq.load(sdkId);
          ttq.page();
        })(window, document, 'ttq');
  
        // Optional cleanup
        return () => {
          const script = document.querySelector('script[src*="tiktok.com/i18n/pixel/events.js"]');
          if (script) {
            script.parentNode?.removeChild(script);
          }
        };
      }
    }, [sdkId]);
  };

export const tikTokIdentify = (params: any) => {
    // hashEmail(params?.userInformation?.userEmail)
    // hashPhoneNumber(params?.userInformation?.userPhoneNumber)
    window.ttq.identify({
        email: params?.userInformation?.userEmail,
        phone_number: formatPhoneNumber(params?.userInformation?.userPhoneNumber),
        external_id: '12345',
        content_id: params?.leadIdToken
    });
}

export const tikTokTrack = (params: any) => {
    console.log('params', params);
    // Get the full URL
    var fullUrl = window.location.href;

    // Create a URL object
    var url = new URL(fullUrl);

    // Get the base URL without query parameters
    var baseUrl = url.origin + url.pathname;
    // `${window.location.protocol}//${window.location.host}${path}`;
    window.ttq.track('SubmitForm', {
        contents: [{
            content_id: params?.leadIdToken,
            content_type: params?.areaStudy,
            content_name: params?.degreeInterest,
        }],
        email: params?.userInformation?.userEmail,
        user: {
            email: params?.userInformation?.userEmail,
            ttclid: params?.ttclid,
            // ip: "77.111.246.19"
        },
        value: '1',
        currency: 'USD',
        page: {
            url: baseUrl,
        }
    });
} 	

