import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Link,
  useMediaQuery,
  Collapse,
  IconButton
} from "@mui/material";
import { useContext,useState } from "react";
import { WebContext } from "../contextProvider/webContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

export const ListingCards = (item: any) => {
  const isDesktop = useMediaQuery('(min-width:600px)');
  const { webContent, setWebContent } = useContext(WebContext);
  const { mBrandName, headContent, mPosition, bodyContent, imageURL, clickURL, Commission, requestData } = item
  const [expand, setExpand] = useState<any>(mPosition === 1);
  const handleVisitSiteClick = () => {

    const conversionValue = Commission
    const transactionID = `${requestData}-${mPosition}`

    window.dataLayer.push({
      event: 'mm-cod-gtag',
      conversionValue: conversionValue,
      transactionID: transactionID
    });
  }

  
  const handleVisitSiteClickMobile = () => {

    handleVisitSiteClick();
    window.open(clickURL, '_blank');
  };
  return isDesktop ?(
    <Box>
      <Card className="listing-card"
        onClick={handleVisitSiteClick}
        component={Link}
        href={clickURL}
        target="_blank"
        sx={{
          marginBottom: '15px',
          textDecoration: "none",
        }}>
        <Box className="info-1">
          {mPosition === 1 ? <Box className="list-rank-count first">{mPosition}</Box> : <Box className="list-rank-count">{mPosition}</Box>}
          <CardMedia
            component="img"
            image={imageURL}
            alt=""
          />
        </Box>

        <CardContent className="info-2">
          <Typography component="div" variant="h5" fontWeight="600" mb={1} className="brand-name">
            {mBrandName}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="p"
            lineHeight={1.3}
            fontWeight="600"
            className="subtitle"
          >
            {/* {headContent} */}
            <FontAwesomeIcon icon={faGraduationCap} style={{ marginTop: '1%', color: webContent?.defaultData?.primaryColor, fontSize: '19px' }}/>  {headContent}
          </Typography>

          {/* TODO */}
          <div dangerouslySetInnerHTML={{ __html: bodyContent }} />
          <Box textAlign="center" mt={2}>
            <Button
              component={Link}
              href={clickURL}
              target="_blank"
              variant="contained"
              size="large"
              sx={{
                textTransform: "none",
                fontSize: "18px",
                borderRadius: "8px",
                minWidth: "150px",
                backgroundColor:  webContent?.defaultData?.primaryColor,
                "&:hover": {
                  backgroundColor: webContent?.defaultData?.primaryColor
                }
              }}
              onClick={handleVisitSiteClick}
            >
              Visit Site
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  ): (
    <Box>
      <Card
        className="mobile-listing-card"
        // onClick={handleVisitSiteClick}
        // component={Link}
        // href={clickURL}
        // target="_blank"
        sx={{
          marginBottom: '15px',
          textDecoration: 'none',
          position: 'relative',
        }}
      >
        {mPosition === 1 ? (
          <Box className="list-rank-count first">{mPosition}</Box>
        ) : (
          <Box className="list-rank-count">{mPosition}</Box>
        )}
        <CardContent className="info-2">
        <Box onClick={handleVisitSiteClickMobile} >
            <Typography
              component="div"
              variant="h5"
              fontWeight="600"
              mt={2.5}
              className="mBrandName"
            >
              {mBrandName}
            </Typography>
            </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              // padding: '16px', // Adjust padding as needed
              boxSizing: 'border-box', // Ensure padding doesn't affect width
            }}
          >
            {/* Logo */}
            <Box
              className="logo"
              sx={{ display: 'flex', alignItems: 'center' }}
              onClick={handleVisitSiteClickMobile}
            >
              <img src={imageURL} width="150px" alt="Logo" />
            </Box>

            {/* Button */}
            <Button
              component="a"
              href={clickURL}
              target="_blank"
              variant="contained"
              color="success"
              size="large"
              sx={{
                textTransform: 'none',
                fontSize: '18px',
                borderRadius: '8px',
                minWidth: '150px',
                backgroundColor: webContent?.defaultData?.primaryColor,
              }}
              onClick={handleVisitSiteClick}
            >
              Visit Site
            </Button>
          </Box>
          <Box onClick={handleVisitSiteClickMobile} pt="8px">
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="p"
              lineHeight={1.3}
              fontWeight="600"
              className="subtitle"
            >
              <FontAwesomeIcon
                icon={faGraduationCap}
                style={{
                  marginTop: '1%',
                  color: webContent?.defaultData?.primaryColor,
                  fontSize: '19px',
                }}
              />
          {headContent}
            </Typography>
            <Collapse in={expand}>
              <div dangerouslySetInnerHTML={{ __html: bodyContent }} />
            </Collapse>
          </Box>
          <Box textAlign="center">
            <IconButton onClick={() => setExpand(!expand)}>
              {expand ? (
                <ArrowDropUpIcon
                  sx={{
                    fontSize: '1.7rem',
                    backgroundColor: webContent?.defaultData?.primaryColor,
                    borderRadius: '100%',
                    color: 'white',
                  }}
                />
              ) : (
                <ArrowDropDownIcon
                  sx={{
                    fontSize: '1.7rem',
                    backgroundColor: webContent?.defaultData?.primaryColor,
                    borderRadius: '100%',
                    color: 'white',
                  }}
                />
              )}
            </IconButton>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
