import React, { useState, useEffect, useContext } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getResponse } from '../Service';
import { PSYCHOLOGY_STUDY, s3BannerImage } from '../common/url';
import Header from './Header';
import { WebContext } from '../contextProvider/webContextProvider';

const Banner = (props: any) => {
  const { selectedDegreeInterest, selectedAreaStudy, selectedMProgram } =
    props.bannerProps;
  const { webContent } = useContext(WebContext);
  const [loading, setLoading] = useState(false);
  const [headerData, setHeaderData] = useState<any>('');
  const updateDate = new Date();
  const pathName = useLocation();
  const [searchParams] = useSearchParams();
  const currentPath = pathName.pathname;
  const pathCheck = currentPath === '/';
  const date =
    ('0' + (updateDate.getMonth() + 1)).slice(-2) +
    ' / ' +
    ('0' + updateDate.getDate()).slice(-2) +
    ' / ' +
    updateDate.getFullYear();
  const perviousYear = updateDate.getFullYear();
  const nextYear = perviousYear + 1;
  const degreeInterestName =
    props?.bannerProps?.selectedDegreeInterest?.value === '1'
      ? 'Career Training'
      : props?.bannerProps?.selectedDegreeInterest?.type;
  const mProgramName = props?.bannerProps?.selectedMProgram;
  const newDegreeInterestType =
    props?.bannerProps?.selectedDegreeInterest?.value === '1';
  const filterMProgram = mProgramName?.text?.includes('General')
    ? mProgramName?.text.replace('General', '').trim()
    : mProgramName?.text;
  const dynamicBannerNameFirst = searchParams.get('t1');
  const dynamicBannerNameSecond = searchParams.get('t2');
  const dynamicBannerNameThird = searchParams.get('t3');

  const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const capitalizeWords = (words: string | null) =>
    words
      ? words
          .split(' ')
          .map((word: string) => capitalizeFirstLetter(word))
          .join(' ')
      : '';

      const capitalizedT1 = dynamicBannerNameFirst
      ? capitalizeWords(dynamicBannerNameFirst)
      : (
          webContent?.defaultData?.defaultURL.find(
            (item: any) => item.key === 't1'
          )?.value || ''
        ).replace(/\+/g, ' ');
    const capitalizedT2 = dynamicBannerNameSecond
      ? capitalizeWords(dynamicBannerNameSecond)
      : (
          webContent?.defaultData?.defaultURL.find(
            (item: any) => item.key === 't2'
          )?.value || ''
        ).replace(/\+/g, ' ');
    const capitalizedT3 = dynamicBannerNameThird
      ? capitalizeWords(dynamicBannerNameThird)
      : (
          webContent?.defaultData?.defaultURL.find(
            (item: any) => item.key === 't3'
          )?.value || ''
        ).replace(/\+/g, ' ');
  

  useEffect(() => {
    // console.log('selectedDegreeInterest', selectedDegreeInterest);
  }, [selectedDegreeInterest]);

  const replaceTemplateString = (value: any) => {
    return webContent?.bannerData?.bannerTitle_2.replace(
      /\$\{cat_name\}/g,
      value
    );
  };

  return (
    <Box>
      <Box className="banner-section">
        <Box>
          {webContent?.bannerData?.uploadImage && (
            <img src={webContent?.bannerData?.uploadImage} alt="" />
          )}
          <Container className="mw-1170">
            <Box className="banner-content">
              <React.Fragment>
                {/* <Typography
                  component="h1"
                  fontSize="36px"
                  fontWeight="bold"
                  lineHeight="1.05"
                >
                  <span>
                    {' '}
                    {dynamicBannerNameFirst
                      ? capitalizedT1
                      : webContent?.bannerData?.bannerTitle_1}{' '}
                    <span className={degreeInterestName ? `text-green` : ''}>
                      {degreeInterestName}{' '}
                      {!degreeInterestName
                        ? 'Degrees in'
                        : newDegreeInterestType
                        ? 'Programs in'
                        : ' in'}{' '}
                      {filterMProgram ? filterMProgram : mProgramName}{' '}
                    </span>
                  </span>
                  <br />
                  <span className="inner-span">
                    {dynamicBannerNameSecond ? (
                      `${capitalizedT2} ${
                        !loading
                          ? webContent?.defaultData?.state &&
                            webContent?.defaultData?.state !== 'undefined'
                            ? ` for ${webContent?.defaultData?.state}`
                            : ' Near You'
                          : ''
                      }`
                    ) : (
                      <>
                        {webContent?.bannerData?.bannerTitle_2}
                        {!loading
                          ? webContent?.defaultData?.state &&
                            webContent?.defaultData?.state !== 'undefined'
                            ? ` for ${webContent?.defaultData?.state}`
                            : ' Near You'
                          : ''}
                      </>
                    )}
                  </span>
                </Typography> */}
                <Typography
                  component="h1"
                  fontSize="36px"
                  fontWeight="bold"
                  lineHeight="1.05"
                >
                  {pathCheck && selectedDegreeInterest?.value ? (
                    <span>
                      {capitalizedT1
                        ? capitalizedT1
                        : webContent?.bannerData?.bannerTitle_1}{' '}
                      <span
                        style={{
                          color: selectedDegreeInterest?.value
                            ? `${webContent?.defaultData?.primaryColor}`
                            : '',
                        }}
                      >
                        {selectedDegreeInterest?.value && degreeInterestName}{' '}
                        {/*  {'Programs'} */}
                        {/* {!degreeInterestName
                          ? 'Degrees in'
                          : newDegreeInterestType
                          ? 'Programs in'
                          : ' in'} */}
                      </span>
                    </span>
                  ) : (
                    <span>
                      {''}
                      {capitalizedT1
                        ? capitalizedT1
                        : webContent?.bannerData?.bannerTitle_1}{' '}
                      <span
                        style={{
                          color: !pathCheck && degreeInterestName
                            ? `${webContent?.defaultData?.primaryColor}`
                            : '',
                        }}
                      >
                        {degreeInterestName}{' '}
                        {!degreeInterestName
                          ? 'Degrees in'
                          : newDegreeInterestType
                          ? 'Programs in'
                          : ' in'}{' '}
                        {filterMProgram ? filterMProgram : mProgramName?.text}{' '}
                      </span>
                      <span className="minW-768-inline">
                        {!loading
                          ? webContent?.defaultData?.state &&
                            webContent?.defaultData?.state !== 'undefined'
                            ? ` for ${webContent?.defaultData?.state}`
                            : ' Near You'
                          : ''}
                      </span>
                    </span>
                  )}
                  <br />
                  <span className="inner-span mw-768-d-none">
                    {capitalizedT2 ? (
                      `${capitalizedT2} ${
                        !loading
                          ? webContent?.defaultData?.state &&
                            webContent?.defaultData?.state !== 'undefined'
                            ? ` for ${webContent?.defaultData?.state}`
                            : ' Near You'
                          : ''
                      }`
                    ) : (
                      <>
                        {/* Find Online{' '}
                        {!pathCheck &&
                          selectedAreaStudy.category_id !== '4' &&
                          selectedAreaStudy.category_name}{' '}
                        Colleges  */}
                        {/* {replaceTemplateString(selectedAreaStudy.category_name)} */}
                        {webContent?.bannerData?.bannerTitle_2}
                        {!loading
                          ? webContent?.defaultData?.state &&
                            webContent?.defaultData?.state !== 'undefined'
                            ? ` for ${webContent?.defaultData?.state}`
                            : ' Near You'
                          : ''}
                      </>
                    )}
                  </span>
                </Typography>
                <Typography variant="body1" fontSize="20px">
                  {capitalizedT3
                    ? capitalizedT3
                    : `${webContent?.bannerData?.bannerTitle_3} for ${perviousYear}-${nextYear}`}
                </Typography>
              </React.Fragment>
            </Box>
          </Container>
        </Box>
      </Box>
      {webContent?.defaultData?.advertiserDisToggle && <Header />}
      <Box sx={{ backgroundColor: webContent?.defaultData?.primaryColor }}>
        <Container maxWidth="lg">
          <Grid container columnSpacing={4}>
            <Grid item xs={12} md={8} className="banner-info">
              <Typography
                variant="body1"
                color="white"
                py={1}
                lineHeight="1.05"
              >
                {webContent?.defaultData?.bannerRequestInfo}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} className="banner-head-mobile">
              <Typography
                variant="body1"
                color="white"
                py={1}
                textAlign="center"
                fontSize="20px"
                lineHeight="1.05"
              >
                {' Last Updated:'} {date}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Banner;
