import React, { useEffect, useState, useContext } from 'react';
import {
  Typography,
  Box,
  SelectChangeEvent,
  Container,
  Grid,
} from '@mui/material';
import {
  fetchListingData,
  preparedListingQuery,
  removeSpecialCharacters,
} from '../../Service';
import AdditionalLead from '../AdditionalLead/AdditionalLead';
import { WebContext } from '../../contextProvider/webContextProvider';
import { ResetTvRounded } from '@mui/icons-material';
import { TListingData } from '../../types/common';
import {
  areaStudyOptions,
  degreeInterestOptions,
  mPrograms,
} from '../../mock/mProgramData';
import MenuDropDownComponent from '../Sidebars/MenuDropDownComponent';
import { ListingCards } from '../ListingCards';

const ListingCom = ({ onNext, leadFormData,queryParams }: any) => {
  const { availableOffers, webContent } = useContext(WebContext);
  const [listingData, setListingData] = useState<TListingData[]>([]);
  const [isListingFetching, setListingFetching] = useState<boolean>(false);
  const [requestData, setRequestData] = useState('');
  const [degreeInterestMenuData, setDegreeInterestMenuData] = useState(
    degreeInterestOptions
  );
  const [degreeInterestValue, setDegreeInterestValue] = useState(
    leadFormData?.degreeInterest
  );
  const [areaStudyMenuData, setAreaStudyMenuData] = useState(areaStudyOptions);
  const [areaStudyValue, setAreaStudyValue] = useState(leadFormData?.areaStudy);
  const [mProgramMenuData, setMProgramMenuData] = useState([]);
  const [mProgramValue, setMProgramValue] = useState(leadFormData?.mProgram);
  const [loading, setLoading] = useState(true);
  let [leadFormStep] = useState<any>({
    // areaStudy: leadFormData?.areaStudy?.category_id,
    // mProgram: leadFormData?.mProgram?.program_id,
    // highestEducation: leadFormData?.highestEducationName.tcpaValue,
    // gradYear: leadFormData.highestEducationYear.value,
    // militaryAffiliation: leadFormData.militaryAffiliationName.value
    //   ? leadFormData.militaryAffiliationName.value
    //   : '1',
    // startTimeFrame: leadFormData.duration.tcpaValue,
    // campusType: leadFormData.userLocation.userCampusType.tcpaValue,
    // mPubID: 93552,
    // mPubCampaignID: 45438,
    // webInitiatingURL: 'compareonlinedegrees.com',
    // redirect: 1,
    // gender: leadFormData.userInformation.userGender,
    // zipCode:leadFormData?.userLocation?.userZipCode
    areaStudy: leadFormData?.areaStudy,
    mProgram: leadFormData?.mProgram,
    degreeInterest: leadFormData?.degreeInterest,
    gradYear: leadFormData.highestEducationYear.value,
    campusType: leadFormData.userLocation.userCampusType.tcpaValue,
    militaryAffiliation: leadFormData.militaryAffiliationName.value
      ? leadFormData.militaryAffiliationName.value
      : '1',
    startTimeframe: leadFormData.duration.tcpaValue,
    webInitiatingURL: ' compareonlinepsychologydegrees.com',
    redirect: 0,
    mPubID: webContent?.defaultData?.pubId,
    mPubCampaignID: webContent?.defaultData?.pubCampaignId,
    adsMax: 100,
    enableFilterByCPC: 1,
    enableFilterByBrand: 1,
    zipCode: leadFormData?.userLocation?.userZipCode,
    gender: leadFormData.userInformation.userGender,
    highestEducation: leadFormData?.highestEducationName.tcpaValue,
    ...queryParams,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (mPrograms[leadFormData?.areaStudy as string]) {
      let datas = mPrograms[leadFormData?.areaStudy as string];
      setMProgramMenuData(datas);
    }
  }, []);

  useEffect(() => {
    const delayedExecution = async () => {
      if (!(availableOffers && availableOffers?.length > 0)) {
        const url = await preparedListingQuery({
          ...leadFormStep,
          redirect: 1,
        });
        try {
          const response = await fetchListingData(leadFormStep);

          setListingData(response?.result);
          setRequestData(response?.mRequestId);
          setListingFetching(true);
        } catch (error: any) {
          // setError(error);
          // setIsContentLoading(false);
        }
        if (!url) return;
        const newTab = window.open(url, '_blank');
        if (newTab) {
          newTab.focus();
        }
      }
    };
    const timeoutId = setTimeout(delayedExecution, 1000);
    return () => clearTimeout(timeoutId);
    // delayedExecution();
  }, []);

  const universityLists = listingData?.map(i => ({
    mPartner: i.mPartner,
    mBrandID: i.mBrandID,
    mBrandName: i.mBrandName,
    bodyContent: i.bodyContent,
    headContent: i.headContent,
    mPosition: i.mPosition,
    clickURL: i.clickURL,
    Commission: i.Commission,
    imageURL: i.imageURL,
  }));

  const fetchUniversities =
    universityLists &&
    universityLists?.map((iList, id) => {
      return (
        <ListingCards
          {...iList}
          key={id}
          requestData={requestData}
          areaStudy={leadFormData?.areaStudy?.category_id}
        />
      );
    });

  const degreeInterestHandle = (event: SelectChangeEvent) => {
    setDegreeInterestValue(event.target.value as string);
  };
  const areaStudyHandle = (event: SelectChangeEvent) => {
    setAreaStudyValue(event.target.value as string);
    if (mPrograms[event.target.value as string]) {
      let datas = mPrograms[event.target.value as string];
      setMProgramMenuData(datas);
      setMProgramValue('');
    }
  };
  const mProgramHandle = (event: SelectChangeEvent) => {
    // setMProgramValue(event.target.value as string)
    const cleanedValue = removeSpecialCharacters(event.target.value as string);
    setMProgramValue(cleanedValue);
  };
  const handleSubmitDropdown = async () => {
    // console.log(leadFormStep);
    // setLeadFormStep((pre: any) => {
    //   return {
    //     ...leadFormStep,
    //     areaStudy: areaStudyValue,
    //     degreeInterest: degreeInterestValue,
    //     mProgram: mProgramValue,
    //   };
    // });

    const response = await fetchListingData({
      ...leadFormStep,
      areaStudy: areaStudyValue,
      degreeInterest: degreeInterestValue,
      mProgram: mProgramValue,
    });

    setListingData(response?.result);
    setRequestData(response?.mRequestId);
    setListingFetching(true);
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Box>
        {availableOffers === null ||
        availableOffers === undefined ||
        availableOffers?.length === 0 ? (
          <>
            <Typography
              component="h5"
              fontSize="20px"
              maxWidth="600px"
              lineHeight="1.3"
              mx="auto"
              fontWeight="800"
              mb={2}
              textAlign="center"
              sx={{ padding: '0px 10px' }}
            >
              {/* Sorry {leadFormData.userInformation.userFirstName}, There were no
              exact program we can find with our partner schools, but here are a
              few schools that may be a good fit */}
              Great {leadFormData.userInformation.userFirstName}! Here are
              online colleges match your search. Select a school to request
              information directly from a college advisor. You can find use the
              search widget below to filter by program.
            </Typography>
            <Container className="mw-1170" sx={{ py: '10px' }}>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} md={8} className="result-card">
                  <section>{fetchUniversities}</section>
                  <Grid item xs={12} className="filter-card mobile">
                    <Box sx={{ width: '100%' }}>
                      <MenuDropDownComponent
                        degreeInterestDDMenu={degreeInterestMenuData}
                        degreeInterestValue={degreeInterestValue}
                        degreeInterestHandler={degreeInterestHandle}
                        areaStudyDDMenu={areaStudyMenuData}
                        areaStudyDDValue={areaStudyValue}
                        areaStudyDDHandler={areaStudyHandle}
                        mProgramDDMenu={mProgramMenuData}
                        mProgramDDValue={mProgramValue}
                        mProgramDDHandler={mProgramHandle}
                        handleSubmit={handleSubmitDropdown}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4} className="filter-card desktop">
                  <Box sx={{ width: '100%' }}>
                    <MenuDropDownComponent
                      degreeInterestDDMenu={degreeInterestMenuData}
                      degreeInterestValue={degreeInterestValue}
                      degreeInterestHandler={degreeInterestHandle}
                      areaStudyDDMenu={areaStudyMenuData}
                      areaStudyDDValue={areaStudyValue}
                      areaStudyDDHandler={areaStudyHandle}
                      mProgramDDMenu={mProgramMenuData}
                      mProgramDDValue={mProgramValue}
                      mProgramDDHandler={mProgramHandle}
                      handleSubmit={handleSubmitDropdown}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </>
        ) : (
          <Box>
            <Box
              className="short-info"
              py={3}
              sx={{ backgroundColor: '#57A773' }}
            >
              <Typography
                component="h2"
                fontSize="23px"
                lineHeight="1.3"
                mx="auto"
                fontWeight="800"
                color="#fff"
              >
                {`Great News, ${leadFormData.userInformation.userFirstName}! We’ve found a great match for you`}
              </Typography>
              <Typography
                component="p"
                fontWeight="600"
                mb={1}
                fontSize="18px"
                color="#fff"
              >
                {`A representative will contact you shortly for next steps`}
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="16px"
                mt={3}
              >
                {/* <img src='https://edu.media-matchers.com/brand/CTU-logo/11212.webp' alt='tcpalogo' width="100px" height="60px" /> */}
              </Box>
            </Box>
            {availableOffers !== null && availableOffers?.length > 0 && (
              <AdditionalLead onNext={onNext} leadFormData={leadFormData} />
            )}
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

export default ListingCom;
