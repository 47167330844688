export const degreeInterestOptions: any = [
    {
        "type": "Career Training",
        "value": "1"
    },
    {
        "type": "Associates Degrees",
        "value": "2"
    },
    {
        "type": "Bachelor's Degrees",
        "value": "3"
    },
    {
        "type": "Master's Degrees",
        "value": "4"
    }
]

export const mPrograms: any =
    [{ "text": "General Psychology", "value": "300" },
    { "text": "Addictions & Recovery", "value": "301" },
    { "text": "Applied Psychology", "value": "302" },
    { "text": "Behavioral Psychology", "value": "303" },
    { "text": "Child Psychology", "value": "304" },
    { "text": "Counseling", "value": "305" },
    { "text": "Forensic Psychology", "value": "307" },
    { "text": "Human Services", "value": "308" },
    { "text": "Organizational Psychology", "value": "310" },
    { "text": "Sociology", "value": "311" }]




