import {
  Box,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  TextField,
  Typography,
  Grid,
  Button,
} from '@mui/material';
import { useFormik } from 'formik';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { leadFormLocationInformationSchema } from '../../validation';
import {
  LeadFormCampusTypeOptions,
  LeadFormUserLocationTitle,
} from '../../constants/leadForms';
import { zipCodesValues } from '../../constants/leadForms/zipcodes';
import { WebContext } from '../../contextProvider/webContextProvider';
const LeadFormLocationInformation = ({
  onNext,
  leadFormData,
  setLeadFormData,
  leadIdToken,
  trustedFormUrl,
}: any) => {

  const { setAvailableOffers,webContent } = useContext(WebContext);
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isValid,
    dirty,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      address: '',
      city: '',
      // campusType: '',
      campusType: {
        tcpaValue: '', // Default to an empty string if no option is selected
        additionalValue: '', // Default to an empty string if no option is selected
      },
      zipCode: '',
      state: '',
    },
    onSubmit: async values => {
      setLeadFormData({
        ...leadFormData,
        userLocation: {
          userAddress: values.address,
          userCity: values.city,
          userState: values.state,
          userCampusType: values.campusType,
          userZipCode: values.zipCode,
        },
        leadIdToken,
      });
      // https://d2gita3jr0qwp2.cloudfront.net/staging/v3/tcpa-match/ping
      try {
        // await axios.post('http://ironsmith-api.leadhoop.com/v2/searches', {
        //   params: {
        //         areaStudy: leadFormData?.areaStudy?.category_id,
        //         mProgram: leadFormData?.mProgram?.program_id,
        //         highestEducation: leadFormData?.highestEducationName.value,
        //         gradYear: leadFormData.highestEducationYear.value,
        //         militaryAffiliation: leadFormData.militaryAffiliationName.tcpaValue ? leadFormData.militaryAffiliationName.tcpaValue : '1',
        //         startTimeFrame: leadFormData.duration.value,
        //         mPubID: 93552,
        //         mPubCampaignID: 45438,
        //         userAddress: values.address,
        //         userCity: values.city,
        //         userState: values.state,
        //         userCampusType: values.campusType.tcpaValue,
        //         userZipCode: values.zipCode,
        //         leadIdToken: leadIdToken,
        //         trustedFormUrl: trustedFormUrl
        //       }
        // })
        await axios
          .get(
            'https://d2thw90mo99gar.cloudfront.net/production/v3/tcpa-match/ping?',
            {
              params: {
                areaStudy: leadFormData?.areaStudy?.category_id,
                mProgram: leadFormData?.mProgram?.program_id,
                highestEducation: leadFormData?.highestEducationName.value,
                gradYear: leadFormData.highestEducationYear.value,
                militaryAffiliation: leadFormData.militaryAffiliationName
                  .tcpaValue
                  ? leadFormData.militaryAffiliationName.tcpaValue
                  : '1',
                startTimeFrame: leadFormData.duration.value,
                mPubID: 93552,
                mPubCampaignID: 45438,
                userAddress: values.address,
                userCity: values.city,
                userState: values.state,
                userCampusType: values.campusType.tcpaValue,
                userZipCode: values.zipCode,
                leadIdToken: leadIdToken,
                trustedFormUrl: trustedFormUrl,
              },
            }
          )
          .then(res => {
            localStorage.setItem('tcpaPingResponse', JSON.stringify(res.data));
          });
        onNext();
      } catch (err) {
        console.log(err);
      }
    },
    validationSchema: leadFormLocationInformationSchema,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  // useEffect(() => {
  //   if (isValid && dirty) {
  //     handleSubmit();
  //   }
  // }, [isValid, dirty, handleSubmit]);

  const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const zip = event.target.value.replace(/[^0-9]/g, '').slice(0, 5);
    handleChange({
      target: { name: 'zipCode', value: zip },
    });

    if (zip.length === 5) {
      setFieldTouched('zipCode', false);
      let foundState = '';
      for (const range in zipCodesValues) {
        if (zipInRange(zip, range)) {
          foundState = zipCodesValues[range];
          break;
        }
      }
      setFieldValue('state', foundState);
    } else {
      setFieldTouched('zipCode', true);
      setFieldValue('state', '');
    }
  };

  const zipInRange = (zip: string, range: string) => {
    const [start, end] = range.split('-');
    return zip >= start && zip <= end;
  };

  const handleKeyPress = (event: any) => {
    const charCode = event.key.charCodeAt(0);
    if (
      (charCode >= 65 && charCode <= 90) ||
      (charCode >= 97 && charCode <= 122)
    ) {
      return;
    }
    event.preventDefault();
  };

  const handleZipCode = (event: any) => {
    return event.target.value.replace(/[^0-9]/g, '');
  };
  return (
    <Box>
      <Box textAlign="center" mb={[4, 0]}>
        <Typography
          component="h5"
          fontSize="30px"
          maxWidth="600px"
          lineHeight="1.3"
          mx="auto"
          fontWeight="800"
          mb={2}
        >
          {LeadFormUserLocationTitle}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              sx={{ background: '#ffffff' }}
              label="Address *"
              name="address"
              onChange={handleChange}
              value={values.address}
              onBlur={handleBlur}
            />
            {errors.address && touched.address ? (
              <Box color="#db2424">{errors.address} </Box>
            ) : null}
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              sx={{ background: '#ffffff' }}
              label="City *"
              name="city"
              onChange={handleChange}
              value={values.city}
              onBlur={handleBlur}
              // inputProps={{ onKeyPress: handleKeyPress }}
            />
            {errors.city && touched.city ? (
              <Box color="#db2424">{errors.city} </Box>
            ) : null}
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              sx={{ background: '#ffffff' }}
              label="Zip Code *"
              name="zipCode"
              onChange={handleZipCodeChange}
              value={values.zipCode}
              onBlur={handleBlur}
            />
            {errors.zipCode && touched.zipCode ? (
              <Box color="#db2424">{errors.zipCode} </Box>
            ) : null}
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              sx={{ background: '#ffffff' }}
              label="State *"
              name="state"
              onChange={handleChange}
              value={values.state}
              disabled
              onBlur={handleBlur}
            />
            {errors.state && touched.state ? (
              <Box color="#db2424">{errors.state} </Box>
            ) : null}
          </Grid>

          <Grid item xs={12}>
            <FormControl sx={{ width: '100%', background: '#ffffff' }}>
              <InputLabel>Campus Type *</InputLabel>
              <Select
                label="Campus Type *"
                name="campusType"
                onChange={handleChange}
                value={values.campusType}
                onBlur={handleBlur}
              >
                {LeadFormCampusTypeOptions.map((typeOption, index) => (
                  <MenuItem value={typeOption as any} key={index}>
                    {typeOption.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors.campusType && touched.campusType ? (
              <Box color="#db2424">
                {errors.campusType.additionalValue ||
                  errors.campusType.tcpaValue}{' '}
              </Box>
            ) : null}
          </Grid>

          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="16px"
              mt={3}
            >
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{
                  textTransform: 'none',
                  fontSize: '18px',
                  borderRadius: '8px',
                  minWidth: '150px',
                  backgroundColor: webContent?.defaultData?.primaryColor,
                  '&:hover': {
                    backgroundColor: webContent?.defaultData?.primaryColor,
                },
                }}
                disabled={!(dirty && isValid)}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default LeadFormLocationInformation;
